<template>
  <div>
    <v-btn small class="ml-2" color="" @click="dialog = true"
      ><v-icon>mdi-format-list-numbered</v-icon>Delivery Receipt</v-btn
    >
    <!-- <v-btn @click="dialog = true"></v-btn> -->
    <v-dialog v-model="dialog" persistent width="900">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <div class="d-flex">
            <v-img src="@/assets/jmbcomputers.png" max-width="40%"></v-img>
            <v-spacer></v-spacer>
            <v-radio-group
              v-model="form.print_type"
              row
              @change="change_print_type"
            >
              <v-radio label="Mixed" value="mixed"></v-radio>
              <v-radio label="Desktop" value="desktop"></v-radio>
              <v-radio label="Laptop" value="laptop"></v-radio>
              <v-radio label="Printer" value="printer"></v-radio>
            </v-radio-group>
          </div>
          <div class="d-flex">
            <p>Invoice Items</p>
            <v-spacer></v-spacer>
          </div>
          <v-data-table :items="item.invoice_items" :headers="headers">
            <template v-slot:item.warranty="{ item }" v-if="mixed">
              {{ get_warranty(item) }}
            </template>
          </v-data-table>
          <v-textarea label="Remarks" v-model="remarks"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Print</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">printDeliveryReceiptModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex/dist/vuex.common.js";

export default {
  props: ["item"],
  data() {
    return {
      mixed: true,
      remarks: "",
      headers: [
        { text: "ID", value: "id" },
        { text: "Item Description", value: "item.item_name" },
        { text: "Unit", value: "item.unit" },
        { text: "Price", value: "item.selling_price" },
        { text: "Quantity", value: "quantity" },
        { text: "Total Price", value: "amount" },
        { text: "Warranty", value: "warranty" },
      ],
      dialog: false,
      loading: false,
      form: { print_type: "mixed" },
    };
  },
  computed: {
    ...mapGetters({
      default_server_url: "auth/default_server_url",
    }),
  },
  methods: {
    change_print_type() {
      if (this.form.print_type == "mixed") {
        this.remarks = this.item.purpose;
      } else if (this.form.print_type == "desktop") {
        this.remarks =
          "Desktop package warranty: 1year JMB Service Warranty, 6months Parts Replacement.";
      } else if (this.form.print_type == "laptop") {
        this.remarks =
          "Laptop Warranty: 6months Parts Replacement, 1year JMB Service Warranty, 2years Brand Service Warranty.";
      } else if (this.form.print_type == "printer") {
        this.remarks =
          "Printer Warranty: 3months JMB Service Warranty, 2years Brand Service Warranty.";
      }
    },
    include_warranty(type) {
      return type;
    },
    get_warranty(item) {
      let warranty =
        item.item.warranty.warranty_count +
        "-" +
        item.item.warranty.warranty_duration;
      return warranty;
    },
    submit() {
      let url =
        this.default_server_url +
        "/print_delivery_receipt/" +
        this.item.id +
        "/" +
        this.form.print_type +
        "/" +
        this.remarks;
      window.open(url);
      // console.log(url);
    },
  },
  created() {
    this.remarks = this.item.purpose;
  },
};
</script>

<style lang="scss" scoped></style>
