<template>
  <div>
    <v-btn @click="dialog = true" color="" class="" small
      ><v-icon class="mr-">mdi-printer</v-icon>Sales Invoice</v-btn
    >
    <v-dialog v-model="dialog" width="300" persistent>
      <v-card>
        <v-card-text class="pa-4">
          <h3 class="text--center font-weight-bold mb-4">
            Do you want to display price?
          </h3>
          <div class="d-flex justify-center">
            <v-btn @click="print_invoice(item, 1)" class="mr-2" color="primary">
              Yes
            </v-btn>
            <v-btn @click="print_invoice(item, 0)" class="mr-2" color="error">
              No
            </v-btn>
            <v-btn @click="dialog = false">Close</v-btn>
          </div>
        </v-card-text>
      </v-card></v-dialog
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters({
      default_server_url: "auth/default_server_url",
    }),
  },
  methods: {
    print_invoice(item, display_price) {
      let type = item.is_quote == 1 ? "quote" : "invoice";
      var url =
        this.default_server_url +
        "/print" +
        "/" +
        type +
        "/" +
        item.id +
        "/" +
        display_price;
      this.dialog = false;
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped></style>
